import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather';

type Props = {
    item: any
}

const FAQAccordion = ({ item }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }
  return (
    <div className='faq-section__item'>
        <div className="faq-section__item-title" onClick={handleOpen}>
            <p>{item.question}</p>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
       {isOpen && (
        <div className="faq-section__item-content">
            <div dangerouslySetInnerHTML={{__html: item.answer }} />
        </div>
        )}
    </div>
  )
}

export default FAQAccordion