import React from 'react'
import Contact from '../components/Contact'
import FAQ from '../components/FAQ'
import Features from '../components/Features'
import Hero from '../components/Hero'

const Home = () => {
  return (
    <div>
      <Hero />
      <Features />
      <FAQ />
      <Contact />
    </div>
  )
}

export default Home