import React from 'react'
import { ArrowRight } from 'react-feather'
import HeroImage from '../assets/images/hero.png'

const Hero = () => {
  // const scroll = (value: string) => {
  //   const section: any = document.querySelector( value );
  //   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  // }; 

  const goToSalesForm = () => {
    window.open('http://bit.ly/3wTYma2', '_blank');
  }

  return (
    <div className='page-hero__wrapper'>
      <div className="page-body__section">
        <div className="page-content__wrapper">
          <div className="page-content">
            <h1>Retain/Reward your Customers.</h1>
            <p>A Customer Loyalty As A Service(CLAAS) platform that allows businesses to acquire, Reward and Retain customers</p>
            <div className="page-content__actions">
              <button className="signup-btn">
                Get Started <ArrowRight />
              </button>
              <button className="login-btn" onClick={goToSalesForm}>
                Talk to sales
              </button>
            </div>
          </div>
        </div>
        <div className="page-image__section">
          <img src={HeroImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Hero