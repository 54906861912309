import React from 'react'
import PlayStore from '../assets/icons/playstore.svg'
import AppleStore from '../assets/icons/applestore.svg'
import ContactIcon from '../assets/icons/contact.svg'

const Contact = () => {
  return (
    <div className='contact-section__wrapper' id='contact'>
      <div className="contact-section__section">
        <div className="contact-item">
          <h5>For Business</h5>
          <p>It takes 10 minutes to get started and make your customers happy</p>
          <div className="action">
            <span>Talk to sales</span>
            <span>
              <img src={ContactIcon} alt="" />
            </span>
          </div>
        </div>
        <div className="contact-item">
          <h5>For Consumers</h5>
          <p>Earn points on the go. It takes 2 minutes to get started</p>
          <div className="action">
            <span>Get bond app</span>
            <span>
              <img src={PlayStore} alt="" />
            </span>
            <span>
              <img src={AppleStore} alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact