import React from 'react'

const TermsContent = () => {
  return (
    <div className='legal-page__content'>
      <p className="content-title">
        What is The Bond Loyalty App?
      </p>
      <p>
        <b>The Bond Loyalty App</b> is a platform designed to assist businesses with attracting, retaining and engaging customers through loyalty programs. The product offering aims to improve the outcomes of loyalty programs by ensuring an effective management of Loyalty Schemes on behalf of merchants and vendors.
      </p>
      <p>
        The Bond Loyalty App is developed by <b>C-ONE VENTURES PLATFORM LIMITED (“C-One”)</b>, a high growth technology driven company.
      </p>
      <p>
        These Terms &amp; Conditions govern and apply to the grant of, access to and use of the suite of services (“Services”) on the App by a User. By accessing, registering and/or using this service, you agree to be bound by these Terms and all other terms and policies that are applicable to any other account or service accessible through this App (collectively referred to as “Terms”).
      </p>
      <p className="content-title">
        Meaning of words:
      </p>
      <p>
        <b>'We',</b> <b>'Us'</b> and <b>'Our'</b> means C-One.
      </p>
      <p>
        <b>'User/you'</b> is anyone who has a Bond Loyalty App account or has access to your Bond account.
      </p>
      <p>
        <b>'App'</b> means the software we offer on compatible iOS and Android devices and on the web, with which you can transact and otherwise access a The Bond Loyalty App account.
      </p>
      <p>
        <b>'A Bond Account'</b> means a Personal account that exists on the Bond Loyalty App ('The Bond Loyalty App Account') in your name tied to your email and/or phone number when you register on the App.
      </p>
      <p>
        <b>'Customer'</b> means a person who patronises a Merchant for the provision of goods/ services.
      </p>
      <p>
        <b>'Device'</b> means a personal computer, telephone, mobile phone, personal digital assistant or any other electronic device, including wireless devices that will allow you to access the App.
      </p>
      <p>
        <b>“Merchant”</b> means a registered vendor with Loyalty Programs hosted on the Bond Loyalty App.
      </p>
      <p>
        <b>'Mobile number'</b> means the mobile phone number registered in Nigeria which you have nominated to use on or with the device or devices you use to access the App.
      </p>
      <p>
        <b>'PIN/Password'</b> means the confidential personal identification number you select to log in to your Bond account, validate transactions and verify your virtual identity.
      </p>
      <p>
        <b>'Prohibitions on Use'</b> You shall not access or use your Bond account, fraudulent, malicious or defamatory purpose
      </p>
      <p>
        <b>'Password and PIN Security'</b> Your Password, PIN and Identification Question must be unique and not easily guessed. You must always keep your Password, PIN and Identification Questions strictly confidential.
      </p>
      <p>
        You agree to notify Us immediately if you become aware of any unusual, suspicious or fraudulent activity on your Bond account.
      </p>
      <p>
        <b>'Transaction'</b> means any recording or redemption of loyalty points to or from your Bond account.
      </p>
      <p>
        <b>‘The Bond Wallet’</b> means a stored value account ('Wallet') in your name tied to your email and/or phone number registered on the App for the purpose of housing your loyalty points from all Merchants with whom you transact, that are hosted on the Bond Loyalty App.
      </p>
      <p className="content-title">
        How to Contact Us:
      </p>
      <p>
        You can contact us through the “Support” section on your App; or send us an email at support@bondapp.co.
      </p>
      <p className="content-title">
        How We Will Connect with You:
      </p>
      <p>
        Our primary contact method shall be in accordance with your preferred communication channel, which may be by email or text messaging. On particular occasions, where other means of contact may be necessary, we will inform you in advance of such requirement.
      </p>
      <p>
        <b>Push notifications:</b> We may also send you instant notifications when you attempt to carry out transactions on the App.
      </p>
      <p>
        <b>Emails, notifications, and texts:</b> Payment receipts, login notifications, newsletters and other relevant information will be sent via email, text messages or in-app notifications
      </p> 
      <p className='content-title'>
        Your Information:
      </p>
      <ul>
        <li>Information we collect</li>
        <p>
          When you sign up on the Bond Loyalty App, we collect and retain your username, legally identified first name, legally identified last name, date of birth, email, phone number and legally identified gender.
        </p>
        <p>
          On the application, you will be asked to generate: a Password, Passcode for the wallet, transaction PIN, and security questions.
        </p>
        <p>
        Please see the Privacy Policy for full details on the personal data that We hold and how We use it.
        </p>
        <p>
          By accepting these terms, you agree to Our use of your information in furtherance of the provision of the Bond Loyalty App’s Services. If at any point, you are no longer interested in Our use of your information, you may opt-out of our services and close your Bond account.
        </p>
        <li>Incorrect information</li>
        <p>
          If We discover that the information We hold about you is incorrect, We reserve the right to suspend your Bond account until We obtain and verify the correct information in order to protect you (User) and Us.
        </p>
      </ul>
      <p className="content-title">
        Management of Your Bond Wallet
      </p>
      <p>
        Wallets on Bond accounts are hosted by Sterling Bank, Our banking partner for the wallet hosting service provision. To this end we have partnered with Sterling Bank to provide a wallet hosting platform for Users to store their Loyalty Points and to better manage the assessment of Customer loyalty rewards. 
      </p>
      <p>
        Users’ total aggregate loyalty points are aggregated by Us as We are responsible for the registration, consolidation, transfer, and redemption of loyalty points of Customers to and from Merchants on the Bond Loyalty App.
      </p>
      <p className="content-title">
        Storing Loyalty Points on your Bond Wallet:
      </p>
      <p>
        When you create a Bond account, you may collect and store your Loyalty Points earned from purchases from Merchants registered on the Bond Loyalty App. Your Bond account allows you store up Loyalty Points on your Bond Wallet and provides a convenient platform to track your rewards and convert points earned to units of value. 
      </p>
      <p>
        Merchants registered on the Bond Loyalty App are permitted to quantify the value of Loyalty Points earned, as well the standard modes of redemption. 
      </p>
      <p>
        You may redeem the Loyalty Points stored on your Bond Wallet from verified outlets assigned by registered Merchants on the Bond Loyalty App.
      </p>
      <p>
        We may not approve the collection of Loyalty Points into your Bond Wallet if:
      </p>
      <ul>
        <li>Your Bond Account has been suspended</li>
        <li>Your Bond Account has been cancelled or deleted</li>
        <li>We experience technical difficulty in verifying your unique ID</li>
        <li>A Merchant suspends or cancels their Bond Account</li>
        <li>Doing so is in compliance with any applicable laws</li>
      </ul>
      <p>
        Where storage of your Loyalty Points is not approved, a notification of a failed transaction will be provided to you. 
      </p>
      <p className="content-title">
        Responsibility for Loyalty Programs 
      </p>
        <p>Users exercise the absolute discretion in their registration or participation in any Loyalty Program. We do not guarantee the authenticity of the Programs offered by any Merchant hosted on the Bond Loyalty App, neither are We responsible for any disputes that may arise between Users and Merchants, including if a User (i) is unable to redeem their Loyalty Points at a Merchant’s store (ii) Changes the value or redemption methods of the Loyalty Points, or (iii) Suspends or cancels in its entirety, the Merchant’s Loyalty Program. </p>
        <p className="content-title">
          Redeeming Loyalty Points:
        </p>
        <p>Your Bond Wallet may be used to redeem accumulated Loyalty Points from designated Merchants’ stores as indicated by Merchants on the Bond Loyalty App.</p>
        <p className="content-title">
          Checking Your Loyalty Points
        </p> 
        <p>The Bond App shall compile a summary of all the Loyalty Points earned by every User and shall be accessible by you through the App. You shall be sent notifications for every new Loyalty Point received on your Bond App- each with its own unique transaction reference.</p>
        <p className="content-title">
          Management of Loyalty Points 
        </p>
        <p><b>Transfer of Loyalty Points</b></p>
        <p>Users may transfer Loyalty Points to other Users on the Bond Loyalty App from their Bond Wallets. Where a User initiates a transfer of Loyalty Points, the transfer amount shall be deducted from their aggregate holdings on their Bond Wallet and credited to the receiver indicated by the User.</p> 
        <p>We are not liable to you for any loss or damage you suffer as a result of us rejecting, declining, failing to authorise or delaying any transaction to or from your account, where there are sufficient grounds for Us to do so</p>
        <p className="content-title">
          Security
        </p>
        <p><b>Forgot PIN, Passcode</b></p>
        <p>If you forget your App Passcode or log in details or want to change them, you can click on the 'forgot passcode' option on the login page and follow the steps.</p>
        <p>If your PIN is compromised in any way, you are required to change it. You may change your PIN by initiating the “Change PIN” feature within the App</p>
        <p className="content-title">
          Our Content Policy
        </p>
        <p><b>User Content</b></p>
        <p>Services on the App may contain photos, graphics, information, text, or other materials, including Content created or submitted to the App by you or through your account (“Content”). We take no responsibility for, neither do we expressly endorse, support, or guarantee the completeness, truthfulness, accuracy, or reliability of any User’s Content.</p>
        <p className="content-title">
          Removal of Content
        </p>
        <p>We may not screen, edit, or monitor all Content hosted on the App. We may however, in our sole discretion, delete, limit, or remove any Content at any time and for any reason, including your violation of these Terms, or if we are likely to be exposed to liability on account of your Content.</p>
        
        <p className="content-title">
          Prohibited Conducts
        </p>
        <p>When using or accessing the App you must comply with these Terms and all applicable laws, guidelines and regulations. You are strictly prohibited from all of the following:</p>
        <ul>
          <li>Gaining or attempt to gain access to another User’s account or any non-public portions of the App;</li>
          <li>Use the App in any manner that could interfere with, disable, disrupt, overburden, or otherwise impair the Services on the App;</li>
          <li>Use the Services in any manner that we reasonably believe to be an abuse of or fraud on the App or the payment systems connected with the App;</li>
          <li>Upload, transmit, or distribute to or through the Services any viruses, worms, malicious code, or other software intended to interfere with the Services, including its security-related features;</li>
          <li>Use the App to violate applicable law or infringe any person’s or entity's intellectual property rights or any other proprietary rights;</li>
          <li>Access, search, or collect data from the App with intent to store, transfer or sell the personal data of other Users without our prior consent;</li>
          <li>modify, adapt, translate, reverse engineer, decipher, decompile or otherwise disassemble any portion of the App or any software Used on or for the App;</li>
          <li>forge headers or manipulate identifiers in order to disguise the origin of any content submitted to any part of the Community;</li>
          <li>post any content that infringes any patent, trademark, trade secret, copyright or other proprietary rights or rights of publicity or privacy rights of any third party;</li>
          <li>post content that is unlawful, malicious, misleading, defamatory, harmful, threatening, abusive, harassing, vulgar, obscene, libelous, objectionable, inappropriate, improper, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;</li>
          <li>impersonate any person or entity, including, but not limited to C-One’s employee(s) or misrepresent or falsely provide any affiliation to C-One, its subsidiary or affiliates; </li>
          <li>post any content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</li>
          <li>post any unsolicited or unauthorised advertising, promotional materials, "spam," "pyramid schemes," or any other form of solicitation;</li>
          <li>post any material that contains software viruses, or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
          <li>act illegally or unethically or use for any illegal or unethical purposes.</li>
        </ul>
        <p className="content-title">
          User’s Responsibility
        </p>
        <p>We have compiled a list of things you need to do to help us keep your account safe:</p>
        <ul>
          <li>It is your sole responsibility to keep your account safe: Security Details, PIN and any part of your account security must not be shared with third parties, carelessly stored, or disclosed under any circumstance.</li>

          <li>If you leave your Device without logging out of the App, write your PIN down in a public space or share your details with anybody, We will not be liable for any errors or losses that may arise as a result.</li>

          <li>We will use all reasonable efforts to keep the App and service safe at all times, and your security settings will help us fulfil this function. However, if safety issues arise from your default with any part of the App or account security, We will be relieved of any liability which results from the occurrence of a breach.</li>

          <li>If you believe or reasonably suspect that there is a fraudulent act on your account, it is your duty to notify Us immediately. We have set up a notification system for ease of notice. In the event of reports of suspicion of fraud, complaints will be investigated, and updates provided through the contact channels provided. </li>

          <li>You are required to change your security options if you believe any part of your security has been compromised.</li>

          <li>Where you notify Us of your intention to change your password, PIN or security options, We will, with your approval, delete existing details and allow you set up new ones.</li>
        </ul>

        <p className="content-title">
          Report of User Activities
        </p>
        <p>Users may request an audit of their activities on the App. Reports and relevant documents are made available on request and may attract special charges.</p>

        <p className="content-title">
          Liability
        </p>
        <p>Subject to any other provision of these Terms to the extent permitted by law, We are exempted from all liability to you for any of the following:</p>
        <ul>
          <li>any loss or damage you suffer as a result of using your account;</li>
          <li>any delay or failure in processing a transaction on your behalf;</li>
          <li>any transaction which is processed by Us on your behalf;</li>
          <li>any failure, malfunction, delay or error (for any reason) of any equipment, system or software (including, without limitation, the telephone, internet enabled device, software and telecommunications and ISP services you use to access an account or payment facility);</li>
          <li>any unavailability or failure of a facility to accept instructions from you;</li>
          <li>any unauthorised access to, or disclosure of information relating to, your account; or</li>
          <li>any other action taken or not taken in relation to your account.</li>
        </ul>

        <p className="content-title">
          Disclaimer
        </p>
        <p>This App, and its contents are provided 'as is' without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. These warranties are hereby excluded to the fullest extent permissible by law. Under no circumstances shall We be liable for any direct or indirect, special, incidental or consequential damages that may arise from your use of, or inability to use, this App or the services.</p>
        <p className="content-title">
          Closing your Bond Account
        </p>
        <p>You may close your Bond account at your discretion. If You would like to exercise this option, please contact us (see the How to Reach Us section). Please note that your Loyalty Points will be inaccessible via the App upon the closure of your Bond account. You may however be able to redeem your Loyalty Points by making direct requests to the store upon their terms and conditions.</p>
        <p className="content-title">
          Complaints or Feedback or Suggestions
        </p>
        <p>If you have a complaint, please contact us via email at support@bondapp.co</p>

        <p>You may also share your issues, feedback or suggestions via the same email.</p>

        <p>Feedback will be monitored to help improve how We serve you</p>

        <p className="content-title">
          Governing Law
        </p>
        <p>Our rights and obligations and those of Our Users, created under these Terms and other agreements entered into, by Us and Users will be governed in accordance with the laws of the Federal Republic of Nigeria, without giving effect to the conflict of laws principles.</p>

        <p className="content-title">
          Dispute Resolution
        </p>
        <p>All disputes which may arise from the User’s access to and use of the Bond Loyalty App account, application, and website and the application of these terms and conditions shall be resolved in compliance with the provisions of the laws of the Federal Republic of Nigeria exclusively.</p>
        <p>We shall attempt to resolve any disputes, claims or controversy arising out of or in connection with these Terms and Conditions or other contracts with you by mediation at the Multi-Door Courthouse of the High Court of Lagos State.</p>
        <p>Notwithstanding the above, parties shall be entitled to institute an action in any Nigerian court of competent jurisdiction to obtain interim relief or to seek injunctive reliefs in order to protect our interests or enforce any right or obligation arising out of, or in connection with these Terms and Conditions or other applicable contracts.</p>


        <p className="content-title">
          Update of Terms and Conditions
        </p>
        <p>The Terms and Conditions shall always be made available on the App and the Bond Loyalty App website.</p>
        <p>We may make changes to the Terms and Conditions for any reason. If we make changes, We will notify you via our communication channels. (see ‘How We will contact you’ above).</p>
        <p>You may opt-out of the Bond Loyalty App where you disagree with any of the changes made to the existing terms. </p>
        <p>In the event that We do not receive feedback from you on the changes made, We will assume the grant of your consent and acceptance of the new Terms.</p>
    </div>
  )
}

export default TermsContent