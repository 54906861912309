import React from 'react'
import data from '../utils/faq.json';
import FAQAccordion from './FAQAccordion';

const FAQ = () => {
  return (
    <div className='faq-section__wrapper'>
      <div className="faq-section__content">
        <div className="faq-section__title">
          <h5>FAQ about the Bond Loyalty App</h5>
          <p>Customer loyalty is today an important aspect of every business, an aspect that every business strives to gain. Loyalty programs and platforms are being used in many countries to do just that; increase customer loyalty. The Bond Loyalty App was built with the vision to revolutionize the loyalty program in Nigeria.</p>
          <p>To help you understand the how, what, and why of the Bond Loyalty App, here are 10 of the most frequently asked questions about the app. Let&apos;s dive right in!</p>
        </div>
        <div className="faq-section_section">
            {data.map((item, index) => (
                <FAQAccordion key={index} item={item} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default FAQ