import React from 'react'

const LegalHero = (props: {title: string}) => {
  return (
    <div className='legal-hero__wrapper'>
      <h1>{props.title}</h1>
    </div>
  )
}

export default LegalHero