import React from 'react'
import LegalHero from '../components/LegalHero'
import TermsContent from '../components/TermsContent'

const Terms = () => {
  return (
    <div>
      <LegalHero title="Terms And Conditions" />
      <TermsContent />
    </div>
  )
}

export default Terms