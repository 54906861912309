import React, { useState } from 'react'
import ctaOne from '../assets/images/cta-one.png'
import ctaTwo from '../assets/images/cta-two.png'
import PlayStore from '../assets/icons/playstore.svg'
import AppleStore from '../assets/icons/applestore.svg'
import { ChevronDown, ChevronUp } from 'react-feather'

const Features = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div className='features-section__wrapper' id="features">
      <div className="features-section__section">
        <div className="section-item">
          <div className="feature-section_image">
            <div className="image-background">
              <img src={ctaOne} alt="" />
            </div>
          </div>
          <div className="feature-section_content">
            <h2>Seamless Platform at your fingertip.</h2>
            <p><span>Bond Mobile App</span> is a free smartphone app that allows customers to Register, Earn Points, and Redeem their Loyalty points.</p>
            <p><span>The Bond Loyalty app</span> for businesses aims to serve the core objectives of organizations in the areas of client acquisition, client retention, and client communication.</p>
            <div className="app-download">
              <span>Get bond app</span>
              <span>
                <img src={PlayStore} alt="" />
              </span>
              <span>
                <img src={AppleStore} alt="" />
              </span>
            </div>
          </div>
        </div>
        <div className="section-item">
          <div className="feature-section_image">
            <img src={ctaTwo} alt="" />
          </div>
          <div className="feature-section_content">
            <h2>Designed to help your business grow fast.</h2>
            <p>Designed for your business  |  Direct and constant communication with your customers  |  Points System  |  Wallet System  |  Publish Campaigns directly to your customers.</p>
            <span onClick={handleMenuToggle}>
              {isMenuOpen ? 'Show less' : 'Read more'}
              {isMenuOpen ? <ChevronUp /> : <ChevronDown />}
            </span>
            <p className={isMenuOpen ? 'active' : ''}>
              Track and monitor value of your loyalty program for effective reconciliation  |  Takes care of all your loyalty program needs
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features