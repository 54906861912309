import React from 'react'

const PrivacyContent = () => {
  return (
    <div className='legal-page__content'>
      <p className="content-title">
        Effective as of 13th, October 2022
      </p>
      <p>The Bond Loyalty App is a software application designed to help businesses attract, retain and communicate with customers.</p>
      <p>Personal information is essential to the provision of exceptional and personalized products and services, but more important is the protection and security of your personal information. C-One respects individuals’ rights to privacy and the protection of personal information. In this Privacy Policy, we transparently explain how and why we collect, store, share and use your personal information – as well as outline the controls and choices you retain when you share your personal data.</p>
      <p>This Privacy Policy applies to all the features of the Bond Loyalty App and any associated services, now or in the future. The terms governing your use of the Bond Loyalty App are defined in our Terms and Conditions.</p>
      <p>The objectives of this policy include:</p>
      <ol>
        <li>Ensuring that you are informed and understand what personal data we collect about you, the reasons for the collection of your personal data, the extent of our use of your personal data and the entities we share your data with.</li>
        <li>Explaining the way we use the personal data you share with is in order to give you a great experience when you use the Bond Loyalty App.</li>
        <li>Explaining your rights and choices in relation to the personal data we collect and process about you and how we will protect your privacy.</li>
      </ol>
      <p>From time to time, we may develop new or offer additional services on the application. If the introduction of these new or additional services results in any material changes to the way we collect or process your personal data, we will provide you with more information or additional terms or policies. Unless stated otherwise, when we introduce these new or additional services, they will be subject to this Privacy Policy.  </p>
      <p>We hope this helps you to understand our privacy commitments to you. For further clarifications of the terms used in this Policy, please email us at <a href="mailto:support@bondapp.co">support@bondapp.co</a> or leave a message for our support officer on the Bond Loyalty App. </p>
      <p>For information on how to contact us if you ever have any questions or concerns, please see the “Contact Us” section below. Alternatively, if you do not agree with the content of this Policy, then please remember that it is your choice whether you want to use the Product and Services and you may voluntarily opt out of this service and discontinue its use. Our principal address is Block 10, Plot 2, The Lennox Mall, 3, Admiralty Way, Lekki Phase 1, Lagos. </p>


      <p>Your Privacy Rights</p>
      <p> You have certain rights in relation to the personal information we collect as provided by the Nigerian Data Protection Regulation 2019 (NDPR) and other applicable data protection laws/regulations. These include the right to:</p>
      <ul>
        <li>access your personal data; </li>
        <li>rectify/update your information in our possession; </li>
        <li>withdraw your consent to the processing of personal information. This will however not affect the legality of processing carried out prior to the withdrawal of consent; </li>
        <li>object to processing of personal information (ways in which we are using your personal information, including direct marketing). This will only be applicable where there are no legal or operational reasons;</li>
        <li>request that your personal information be made available to you in a common electronic format and/or request that such data be sent to a third party; </li>
        <li>request that your information be erased. However, we are obligated to retain such data if there are valid legal, regulatory, or operational reasons to do so;</li>
        <li>Request that we restrict the processing of your personal information;</li>
        <li>not to be subject to a decision based solely on automated decision-making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect.</li>
      </ul>
      <p>The exercise of these rights is dependent on many factors. In certain instances, we may not be able to comply with these requests, because we have legitimate grounds for the refusal or where the right is not applicable to the particular data obtained from the individual in question. These rights are also subject to the limitations provided in the NDPR and other applicable data protection laws/regulations.</p>
      <p>If you have any questions about your privacy, your rights, or how to exercise them, please refer to the Contact Us section of this policy.</p>

      <p className="content-title">
        THE TYPE OF PERSONAL INFORMATION WE COLLECT FROM YOU
      </p>
        <p>We currently collect and process different categories of personal information for various purposes to provide and improve the services we offer to you. To enable you to sign up for and use the Bond Loyalty App, we collect the following personal data from you:</p>
        <ul>
          <li>Personal identifiers – this includes your name, username, email address, phone number, birth date, and gender. </li>
          <li>Transaction details – this includes your bond loyalty transaction history, wallet ID, and information you provide for the delivery of services to you.</li>
          <li>Information about any of our products and services you currently use and have, or products and services you have applied for or have previously used. </li>
          <li>User Content – this includes content you post to the Bond Loyalty account such as your contact list and location data. Please note that we will only access your contact list, current location, camera, or photos from your device if you give us permission to do so, and we will only access images that you specifically choose to share with us and metadata related to those images, such as the type of file and the size of the image. We will never scan or import your device’s photo library or camera roll.</li>
          <li>Inferences drawn about your interests and preferences based on your usage of the Bond Loyalty App.</li>
          <li>Information about your interactions with the Bond Loyalty App.</li>
          <li>Certain technical data – this may include URL information, online identifiers including cookie data and IP addresses, smart device information (including mobile device unique ID, your mobile operating system and other diagnostic data), mobile network provider, browser type, language and your non-precise location (which may be derived or inferred from certain technical data like your IP address and the language setting of your phone or payment currency). We do not store or upload this information to our servers. We only collect what we need to improve your experience.</li>
        </ul>
        <p>We may also process specific categories of information for specific and limited purposes, such as detecting and preventing financial crime or making our services available to customers.</p>
        <p>We will only process particular types of information after we have obtained your explicit consent or we are otherwise lawfully permitted to do so.</p>
        <p className="content-title">
          How We Gather Personal Information and why we have it.
        </p>
        <p>Most of the personal information we process is collected or provided directly to us by you. The information we collect include:</p>
        <ol>
          <li>Information collected or provided directly to us by you to enable you sign up for and use the Bond Loyalty App;</li>
          <li>Information collected about you when you are accessing and/or using the Bond Loyalty app such as your location data, browser settings, your IP address and other online identifiers;</li>
          <li>Information that we learn about you through our relationship with you and the way you operate your accounts and or services, such as transactions made to and from your wallet; </li>

          <p>We also receive personal information indirectly, from the following sources in the following scenarios:</p>

          <li>Information that we receive from various third parties – third parties who provide services to you or us, including merchants, credit reference, fraud prevention or government agencies and other banks;</li>
          <li>Information we collect from publicly available sources, such as the press, company registers and online search engines.</li>
        </ol>

        <p>In the table below, we have set out the reasons for our processing of your personal information and the associated legal bases that we rely upon as provided in the Nigerian Data Protection Regulation (NDPR) and other applicable data protection laws/regulations.</p>
        <table>
          <tr>
            <th>Processing Purpose</th>
            <th>Lawful Basis under NDPR</th>
          </tr>
          <tr>
            <td>To Provide and personalise the Bond Loyalty App</td>
            <td>
              <ul>
                <li>Performance of a contract</li>
                <li>Legitimate Interest</li>
                <li>Consent</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To understand, diagnose, troubleshoot and fix issues with the Bond Loyalty App</td>
            <td>
              <ul>
                <li>Performance of a contract</li>
                <li>Legitimate Interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To improve product features and design </td>
            <td>
              <ul>
                <li>Legitimate Interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To provide you with tailored content and marketing messages such as recommending similar or related C-One (and related entities and affiliates) products or services we believe you may be interested in</td>
            <td>
              <ul>
                <li>Legitimate Interest</li>
                <li>Consent</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To comply with and enforce applicable legal and regulatory requirements, relevant industry standards, contractual obligations and our internal policies. </td>
            <td>
              <ul>
                <li>Compliance with legal obligations</li>
                <li>Legitimate Interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To verify your identity when you access your account</td>
            <td>
              <ul>
                <li>Performance of a contract</li>
                <li>Compliance with legal obligations</li>
                <li>Legitimate interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To establish, exercise or defend legal claims</td>
            <td>
              <ul>
                <li>Legitimate interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To carry our business research, analytics, planning and forecasting</td>
            <td>
              <ul>
                <li>Legitimate interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To detect fraud, money laundering or terrorism financing activities</td>
            <td>
              <ul>
                <li>Performance of a contract</li>
                <li>Compliance with legal obligations</li>
                <li>Legitimate interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Manage our risks, review your credit or loan eligibility</td>
            <td>
              <ul>
                <li>Performance of a contract</li>
                <li>Legitimate interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Respond to your requests and communicate with you</td>
            <td>
              <ul>
                <li>Consent</li>
                <li>Performance of a contract</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To check your wallet balance and transaction history.</td>
            <td>
              <ul>
                <li>Performance of a contract</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To help recover funds that may have entered your account as a result of a payment sent in error</td>
            <td>
              <ul>
                <li>Compliance with legal obligations</li>
                <li>Legitimate interest</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>To conduct research, contests, surveys and Sweepstakes</td>
            <td>
              <ul>
                <li>Legitimate interest</li>
                <li>Consent</li>
                <li>Performance of a contract</li>
              </ul>
            </td>
          </tr>
        </table>
        <p className="content-title">
          Sharing your personal information 
        </p>
        <p>We share personal information about you and your dealings with us, to the extent permitted by law, with the following:</p>
        <ol>
          <li>Legal/Regulatory Authorities - We may disclose personal information if we determine that for the purposes of national security, law enforcement, or other issues of public importance, a disclosure of personal information is necessary or appropriate. It may be necessary by law, legal process, litigation, and/or requests from public and governmental authorities for the disclosure of your personal information.</li>
          <li>Professional Advisers: Auditors, Legal Advisers, et al. </li>
          <li>Credit Agencies; </li>
          <li>Correspondent Banks;</li>
          <li>External Auditors; </li>
          <li>Strategic partners/service providers – we provide personal data to trusted and authorised third parties who assist us in conducting our business and/or providing services to you. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.</li>
        </ol>
        <p>We also disclose your Personal Information in good faith when we believe it is necessary for us to do so in any of the following circumstances:</p>
        <ol>
          <li>We have your consent to share the information. </li>
          <li>To comply with a legal obligation. </li>
          <li>To bring you improved service across our array of products and services, when permissible under relevant laws and regulations, by disclosing your personal information with C-One’s affiliates or partner bank (i.e., Sterling Bank Plc)</li>
          <li>To protect and defend the rights or property of C-One and its partners. </li>
          <li>To prevent or investigate possible wrongdoing in connection with our Service. </li>
          <li>To protect the personal safety of users of our Service or the public. </li>
          <li>To protect against legal liability. </li>
          <li>In the event of a reorganization, merger, or sale, we may transfer any and all personal information we collect to the relevant third party. </li>
          <li>We find that your actions on our website or application violate any part of our Terms and Conditions of use. </li>
        </ol>
        <p>Whenever we share your personal information with anyone other than for purposes highlighted herein, we will inform you about the details of the exchange. We require that all third parties with whom we share your personal data agree to process such information based on our instructions and in compliance with this privacy policy and any other appropriate confidentiality and security measures.</p>
        <p>Special categories of personal data (also known as sensitive personal data) are part of the information we collect from you. In particular, we may process biometric data (such as facial and voice recognition), for services that require it. Where we process such sensitive personal data, we will do so on the basis that we have your explicit consent to do so, and that it is necessary for reasons of substantial public interest, to establish, and/or to exercise or defend any legal claims. We will carry out processing under applicable laws.</p>
        <p className="content-title">
          How long we will keep your information.
        </p>
        <p>We will keep your personal data for as long as we have a relationship with you, i.e., for as long as you use the app, remain validly subscribed to a newsletter or partake in surveys.</p>
        <p>Following the termination of our relationship with you, (e.g., following the deletion of your account) or cancellation of your subscription to the Service), we will cease to gather or collect any new information about you.</p>
        <p>Once the relationship is terminated, we will only retain personal data for as long as is necessary, depending on the type of personal information and the purposes for which that information is retained. Please, note that retention periods are subject to legal and regulatory requirements.</p>
        <p className="content-title">
          How Do We Protect Your Information? 
        </p>
        <p>We implement a variety of reasonable security measures to protect the security and integrity of your personal information. We use computer safeguards such as firewalls and data encryption, enforce physical access to our buildings and files and only authorise access to personal information to only employees who require the data to fulfil their job responsibilities, or our affiliates as may be required in the provision of our services to you.</p>
        <p>However, the internet is not always a secure medium, and we cannot guarantee the non-occurrence of security breaches. Accordingly, we are not responsible for matters, which include actions of hackers and other unauthorised third parties that attempt to breach our reasonable security procedures. No method of electronic transmission or storage is 100% secure, therefore, we cannot guarantee the absolute security of your Personal Information. It is important that you understand that you also play a crucial role in protecting your personal information.</p>
        <p>Please safeguard your passcodes for your Bond Loyalty account and wallet and do not share them with others. If we receive instructions using your Bond Loyalty account login information, we will consider that you have authorised the instruction.</p>
        <p>You agree to notify us immediately of any unauthorised use of your Bond Loyalty account or any other breach of security. We reserve the right, at our sole discretion, to refuse to provide our Services, terminate Bond Loyalty accounts, and to remove or edit content.</p>
        <p className="content-title">
          Third-Party Policy
        </p>
        <p>Occasionally, at our discretion, we may include or offer third-party products or services or links on our application. These third-party sites have separate and independent privacy policies. We request that you separately review these policies, as we, have no responsibility or liability for the content and activities of linked platforms. Nonetheless, we seek to protect the integrity of our application and welcome feedback about these sites.</p>
        <p className="content-title">
          Cookie Policy
        </p>
        <p>Cookies help us serve you better.</p>
        <p>Think of cookies as crumbs. Every time you visit a website, that website saves bits of your visit so they can keep track of how many times you have visited, the length of time spent, and your activities.</p>
        <p>This information helps the site show you things that are relevant to you based on your past visits. The length of time stored depends on the cookie, but this is generally at least a few minutes and up to two years.</p>
        <p className="content-title">
          Why and How We Use Cookies on Our Website
        </p>
        <p>We use cookies to enhance our customers’ online activity by making navigation easier and providing important security features. </p>
        <p>For the provision of certain services, cookies are a requirement because they help protect customer privacy. For example, cookies automatically terminate the online session if the customer forgets to log out. </p>
        <p>Visitors to our website who have JavaScript enabled are tracked using Google Analytics. Google Analytics collects the following types of information from users: </p>
        <p>1. Web browser used – software manufacturer, and version number </p>
        <p>2. Operating system </p>
        <p>3. Screen colours </p>
        <p>4. JavaScript support </p>
        <p>5. Flash version</p> 
        <p>6. Screen resolution </p>
        <p>7. Network location and IP address: a. May include geographic b. Hostname c. Connection Bandwidth </p>
        <p>8. May include geographic data </p>
        <p>9. Hostname </p>
        <p>10.Connection bandwidth </p>
        <p>11.Time of visit </p>
        <p>12.Pages visited and dwell time on these pages </p>
        <p>13.For referring sites:   a. The referring website URL b. Search engine query used Google Analytics data is shared with Google. For more information on Google’s Privacy Policies, visit <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a> </p>
        <p className="content-title">
          Third-party Cookies 
        </p>
        <p>We allow only our trusted third parties to have their cookies on our website.</p>
        <p>The Bond Loyalty App uses these types of cookies:</p>
        <ul>
          <li>Strictly Necessary Cookies</li>
          <li>Performance Cookies</li>
          <li>Functionality Cookies.</li>
        </ul>

        <p>You can turn functionality cookies off whenever you like.</p>
        <p>You can choose to have your computer issue a notification you each time a cookie is being sent, or you can choose to turn off unnecessary cookies. The privacy settings o your device will determine the ay to limit the use of cookies.</p>
        <p>If you turn your cookies off, some of the features that make your site experience more efficient may not function properly.</p>
        <p>To learn more about cookies and privacy, visit https://www.allaboutcookies.org</p>
        <p className="content-title">
          Information Shared Socially
        </p>
        <p>We are not responsible for maintaining the confidentiality of any information you share publicly or with friends. Please be mindful of your personal privacy needs and the privacy needs of others, as you choose whom to connect with and what to share and make public.</p>
        <p>We cannot control the privacy or security of information you choose to make public or share with others. We also do not control the privacy practices of third-party websites. Please contact those sites and services directly if you want to learn about their privacy practices.</p>
        <p className="content-title">
          Transfer to Other Countries 
        </p>
        <p>Personal data collected within Nigeria may be transferred to and processed by third parties located in other countries. In such instances, We shall ensure that the transfer of your personal data is carried out in accordance with applicable privacy laws/regulations and, in particular, that appropriate contractual, technical, and organisational measures are in place, such as adequacy mechanisms and standard contractual clauses approved by the Attorney General of the Federation.</p>
        <p>For further details of the security measures we use to protect your personal data, please see the “How do we protect your personal information” section of this policy. </p>
        <p className="content-title">
          Children’s Privacy
        </p>
        <p>We target Our Services at persons above the age of 18. However, where users are below 18, they may create a Bond Loyalty account under the supervision and explicit consent of a parent or guardian.  </p>
        <p>Our collection of personally identifiable information from anyone under the age of 18 shall be in accordance with the terms of this Privacy Policy. If you are under the age of 18, you may only subscribe to our Service or submit any information to us in strict compliance with the terms of this Privacy Policy. As a parent or guardian, you shall ensure strict compliance with this Privacy Policy for the subscription of your Child(ren) under the age of 18 to the Bond Loyalty App. </p>
        <p>If we become aware that we have collected Personal Data from children without due verification of parental consent, we shall take steps to remove that information from our servers.</p>
        <p className="content-title">
          Changes to this policy
        </p>
        <p>Based on the changing nature of privacy laws, user needs and our business, we may modify this Privacy Policy from time to time. Any change to our Privacy Policy will be communicated on our upgrades via the application, and this will be effective as soon as published. Accordingly, we encourage periodic reviews of this Privacy Policy for awareness of any changes that may have occurred.</p>
        <p className="content-title">
          Contact Us
        </p>
        <p>If you have any queries about how we use your personal information which is not answered here, you may contact us at <a href="mailto:support@bondapp.co">support@bondapp.co</a>.</p>

       <p>Where you have suffered a breach, we advise that you inform us immediately to enable us take appropriate action within 72 hours of our receipt of your complaint.</p> 

        <p>If you want to make a complaint about how we have handled your personal information, or would like to know more about your rights and how to exercise them, you may contact our Data Protection Officer by sending an email to <a href="mailto:support@bondapp.co">support@bondapp.co</a>.</p> 
        <p>You may also write a letter addressed as follows:</p> 
        <p>
           The Data Protection Officer,
           <br />
          C-ONE VENTURES PLATFORM LIMITED, 
          <br />
          Block 10, Plot 2, The Lennox Mall, 3, Admiralty Way, Lekki Phase 1, Lagos
        </p>
    </div>
  )
}

export default PrivacyContent