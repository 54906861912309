import React from 'react'
import LegalHero from '../components/LegalHero'
import PrivacyContent from '../components/PrivacyContent'

const Privacy = () => {
  return (
    <div>
        <LegalHero title='Privacy Policy' />
        <PrivacyContent />
    </div>
  )
}

export default Privacy