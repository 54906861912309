import React from 'react'
import { Facebook, Instagram, Twitter } from 'react-feather'
import Medium from '../assets/images/medium.png'

const Footer = () => {
  const scroll = (value: string) => {
    const section: any = document.querySelector( value );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }; 

  return (
    <div className='footer-section__wrapper' id="footer">
      <div className="footer-section__section">
        <div className="footer-item">
          <span onClick={() => scroll("#features")}>Features</span>
          <span>Contact Us</span>
          <span>
            <a href="/terms">
              Terms of Use
            </a>
          </span>
          <span>
            <a href="/privacy">
              Privacy Policy
            </a>
          </span>
        </div>
        <div className="footer-item">
          <div className="footer-links">
            <a href="https://www.facebook.com/BondLoyaltyApp" target="_blank" rel='noreferrer'>
                <Facebook />
            </a>
            <a href="https://twitter.com/bond_loyaltyapp" target="_blank" rel='noreferrer'>
                <Twitter />
            </a>
            <a href="https://medium.com/@bondloyaltyapp" target="_blank" rel='noreferrer'>
                <img src={Medium} alt="medium" />
            </a>
            <a href="https://www.instagram.com/bondloyaltyapp/" target="_blank" rel='noreferrer'>
                <Instagram />
            </a>
          </div>
          <div className="search-section">
            <a href="mailto:support@bondapp.co">
              Click to send us an email
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer