import React, { useEffect, useState } from 'react'
import { ArrowRight } from 'react-feather';
import BondLogo from '../assets/images/bond-logo.png'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const isSticky = (e: any) => {
    const header: any = document.querySelector('.page-header__wrapper');
    const scrollTop = window.scrollY;
    scrollTop >= 150 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  const scroll = (value: string) => {
    const section: any = document.querySelector( value );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    setMenuOpen(false);
  }; 

  const handleOpenMenu = () => {
    if (!menuOpen) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }

  const handleLoginPageChange = () => {
    window.open('http://bit.ly/3wTYma2', '_blank');
  }

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const goToSalesForm = () => {
    window.open('http://bit.ly/3wTYma2', '_blank');
  }

  return (
    <div className='page-header__wrapper'>
      <div className="page-header__content">
        <div className="page-logo__wrapper">
          <img src={BondLogo} alt="Bond logo" loading="lazy" />
        </div>
        <div className="page-header__actions">
          <div className="action-item" onClick={() => scroll("#features")}>
            <p>Features</p>
          </div>
          <div className="action-item" onClick={() => scroll("#footer")}>
            <p>Contact Us</p>
          </div>
          <div className="action-item">
            <button className="login-btn" onClick={handleLoginPageChange}>
              Get Started <ArrowRight />
            </button>
            <button className="signup-btn" onClick={goToSalesForm}>
              Talk to sales
            </button>
          </div>
        </div>
        <div className="mobile-menu__section">
          <div className={menuOpen ? "menu-btn open" : "menu-btn"} onClick={handleOpenMenu}>
            <div className="menu-icon" />
          </div>
        </div>
      </div>
      {menuOpen ? (
        <div className="menu-dropdown__content">
          <div className="page-mobile__actions">
            <div className="mobile-item" onClick={() => scroll("#features")}>
              <p>Features</p>
            </div>
            <div className="mobile-item" onClick={() => scroll("#footer")}>
              <p>Contact Us</p>
            </div>
            <div className="mobile-item">
              <button className="login-btn" onClick={handleLoginPageChange}>
                Get Started <ArrowRight />
              </button>
            </div>
            <div className="mobile-item">
              <button className="signup-btn" onClick={goToSalesForm}>
                Talk to sales
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Header